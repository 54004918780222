import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

//Styles
import * as header from '../../styles/Header.module.scss'
import * as integration from '../../styles/Integration.module.scss'
import logo from '../../images/flow_logo.png'

const MicrosoftTeams = ({ pageContext: { locale } }) => {
  const homeLink = `/${locale}/`
  return (
    <div className="wrapper">
      <div className={header.navigation}>
        <Link className={header.logo} to={homeLink}>
          <img alt="Flow Logo" src={logo} />
        </Link>
      </div>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>Overview</h2>
          <div>
            <p>
              Improve your company communication – internally between
              colleagues, and externally with customers. How, you ask? By
              integrating your telephony solution with your collaboration
              platform, you’ll get access to all the essential tools in one
              place.
            </p>
            <p>
              With your telephony in Microsoft Teams, it’s super-easy to keep in
              touch – even across national borders! You can call, chat, and
              transfer calls between colleagues free of charge, and communicate
              with everyone else at affordable rates.
            </p>

            <h3>Don’t switch between apps. Integrate instead.</h3>

            <p>
              Gone are the days when you had to use another app or your mobile
              to make an outbound call. Now, you can call out directly within
              Teams, and it’s all thanks to the integration. Get access to all
              Flow features by integrating with Microsoft Teams. Control your
              PBX and call out to anyone in the world in an easy-to-use
              interface within the Teams ecosystem. All in one environment –
              it’s a truly seamless experience.
            </p>
          </div>
        </div>
      </section>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>Integration features</h2>

          <div className={integration.featuresWrapper}>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Get notified of incoming calls in Teams</h4>
                <span>
                  Accept calls and use Teams to control call handling such as
                  transferring and receiving calls to your fixed number.
                </span>
              </div>
            </div>

            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Decide which number to show</h4>
                <span>
                  Users can control Flow’s caller ID masking within Teams,
                  meaning that the user decides which number to display when
                  calling out (fixed office number, mobile number or company
                  switchboard number).
                </span>
              </div>
            </div>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Call all of your contacts – Teams or not</h4>
                <span>
                  All your contacts are pulled from Outlook Exchange so you can
                  call them directly on their mobile or fixed number – whether
                  they use Microsoft Teams or not.
                </span>
              </div>
            </div>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>One client to answer and handle inbound PBX calls</h4>
                <span>
                  Since an inbound call won’t ring on both clients, your
                  employees can decide what works best for them. This allows for
                  gradual adoption of Teams while maintaining the required
                  customer service levels.
                </span>
              </div>
            </div>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Complete control of the PBX</h4>
                <span>
                  Users can control their Flow profiles directly within Teams,
                  access incoming call lists, recordings, look-up, and
                  voicemails.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>How to setup the integration</h2>

          <div className="setup-list">
            <ol className={integration.olList}>
              <li className={integration.olListItem}>
                Check that your Microsoft License allows telephony integration.
              </li>
              <li className={integration.olListItem}>
                You will also need either a Phone System add-on (for E-licenses)
                or a Business Voice add-on from Microsoft.
              </li>
              <li className={integration.olListItem}>
                Your Flow Retailer will configure the PBX to establish direct
                routing with Microsoft 365. When that’s done, we’ll send you the
                phone numbers.
              </li>
              <li className={integration.olListItem}>
                Now, all you need to do is to configure the users in Microsoft
                365 and in the Flow Admin Portal.
              </li>
              <li className={integration.olListItem}>
                All done! Your telephony solution is now connected to the users
                and phone numbers in the system.
              </li>
            </ol>

            <div className={integration.setupListHint}>
              Your telephony solution is now automatically connected to the
              users and phone numbers in the system. Employees can open the Flow
              app and easily log in with their Microsoft account. By doing so,
              calendars and contacts will automatically sync with Teams.
            </div>
          </div>
        </div>
      </section>

      <section className={`${integration.section} ${integration.contactUs}`}>
        <div className={`${integration.container} ${integration.morePadding}`}>
          <h2>Questions about integrations?</h2>
          <span>
            Contact us at <a href="tel:+46980330859">+46980330859</a> or{' '}
            <a href="mailto:support@joinflow.com">support@joinflow.com</a> to
            learn more.
          </span>
        </div>
      </section>
    </div>
  )
}

MicrosoftTeams.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default MicrosoftTeams
